.body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden; /* Hide horizontal scroll */
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/***
* Home page CSS
***/
/***
* Header
***/
.header {
    background-color: #008CBA;
    color: #FFFFFF;
    background-image: url('./main-logo-header.png');
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px; /* Uniform padding */
    box-sizing: border-box;
    background-size: 200px;
    background-repeat: no-repeat;
    background-position: 20px center;
    background-origin: content-box;
    justify-content: space-between; /* Aligns children with space between */
}

/* Ensure there is a div with class "logo-placeholder" in your HTML, with appropriate width for the logo */
.logoPlaceholder {
    flex-shrink: 0; /* Prevent logo from shrinking */
}

.nav {
    flex-grow: 1; /* Take up remaining space */
    display: flex;
    justify-content: flex-end; /* Align nav items to the right */
    align-self: flex-end;
}

.navList {
    list-style: none;
    margin-top: 50px;
    display: flex;
    padding: 0;
    gap: 1px;
}

.navLink {
    color: #FFFFFF;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: bold;
    padding: 10px 10px;
    display: block;
}
/***
* Three column section
***/
/* Container for the columns */
.threeColumns {
    display: flex;
    justify-content: space-between;
    padding: 40px;
}
/* Individual column styling */
.column {
    flex: 0 1 25%;; /* Each column will take up equal space */
    margin: 0 10px; /* Add some space between columns */
    padding: 20px; /* Add some padding inside the columns */
    background-color: #f4f4f4;
}
/***
* Footer
***/
.footer
{
    background-color: #008CBA;
    font-size: 80%;
    text-align: center;
    font-family: Georgia, 'Times New Roman', serif;
    padding: 2em;
    color: #FFFFFF;
}
.platformDescription {
    text-align: left;
    padding: 10px 20px; /* Add some padding */
    font-size: 1.1rem;
    font-style: italic;
    font-weight: lighter;
    color: #008CBA; /* Set the text color */
    background-color: #fff; /* Set the background color if needed */
    border-top: 1px solid #ccc; /* Line at the top */
    border-bottom: 1px solid #ccc; /* Line at the bottom */
}
/* NEW CSS 12/20 */
.headerContainer {
    background-color: #FFFFFF; /* Or any color that matches your design */
    text-align: center; /* Centers the heading text */
    padding: 10px 0; /* Adds some padding above and below the text */
    display: flex; /* Aligns children in a row */
    justify-content: center; /* Centers the items horizontally */
    align-items: center; /* Centers the items vertically */
}

.headerContainer h1 {
    font-size: 24px; /* Or the size that fits your design */
    color: #333; /* Or any color that matches your design */
    margin: 0 10px; /* Adds some margin to the left and right of the text */
}

.decorativeImage {
    height: 30px; /* Or the height that matches your design */
    width: auto; /* Keeps the image aspect ratio */
}

.decorativeImage.left {
    margin-right: 10px; /* Adds some space between the image and the text */
}

.decorativeImage.right {
    margin-left: 10px; /* Adds some space between the text and the image */
}

.courseContainer {
    display: flex;
    justify-content: center;
    background: #ffffff; /* The blue background */
    padding: 20px;
}

.course {
    background: white; /* White background for the course card */
    margin: 10px;
    text-align: center; /* Center the text */
    width: 300px; /* Adjust the width as needed */
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1); /* Simple shadow for depth */
}

.titleBar {
    background-color: black; /* Black background for the title bar */
    color: white; /* White text */
    padding: 10px 0; /* Padding above and below the text */
    border: 3px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
}

.courseImage {
    width: 100%; /* Make the image fill the container */
}

p {
    padding: 0 20px; /* Padding on the sides of the paragraph */
}

/* Media query */
@media (max-width: 768px) {
    .header {
        flex-direction: column; /* Stack logo and navigation on smaller screens */
        align-items: center; /* Center items vertically and horizontally */
        padding-top: 20px; /* Add padding at the top */
        padding-bottom: 20px; /* Add padding at the bottom for space */
        min-height: 150px; /* Ensure enough space for stacked items */
    }

    .logoPlaceholder {
        margin-bottom: 20px; /* Add space between logo and navigation links */
        display: flex; /* Use flexbox to center the logo */
        justify-content: center; /* Center the logo */
        width: 100%; /* Full width to avoid content overlap */
    }

    .nav {
        width: 100%; /* Take the full width of the header */
        display: flex; /* Make sure this is a flex container */
        justify-content: center; /* Center the navigation container */
    }

    .navList {
        flex-direction: row;
        align-items: center; /* Center the links */
        width: 100%; /* Full width */
        padding-top: 90px; /* Reset default padding */
    }

    .navLink {
        margin: 5px 0; /* Add margin for spacing between links */
        text-align: center; /* Center the text of each link */
        width: 100%; /* Full width for each link */
    }
    .threeColumns {
        flex-direction: column;
    }
    .column {
        margin-bottom: 20px; /* Adds space between stacked columns on small screens */
    }
    @media (max-width: 768px) {
        .courseContainer {
            flex-direction: column;
        }
    }
}