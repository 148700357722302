/* Override Swiper's default shadow colors */
.swiperSlideShadowLeft, .swiperSlideShadowRight {
    background-image: linear-gradient(to left, rgba(200, 200, 200, 0.5), transparent);
}
.swiperButtonNext, .swiperButtonPrev {
    color: #000;
}
.slideContent {
    position: relative;
}
.swiperSlide { /* This class needs to be added to each SwiperSlide element */
    position: relative;
}
.slideText {
    position: absolute;
    bottom: 0; /* Align text to the bottom of the slide */
    left: 0;
    width: 96.5%; /* Ensure the text container spans the width of the slide */
    background: rgba(0, 0, 0, 0.7); /* Dark background for text for readability */
    color: #f3f2f2; /* Ensure text color is white for contrast */
    padding: 10px; /* Padding around text */
    z-index: 10; /* Higher z-index to bring text to the foreground */
}

.slideContent:hover .slideText {
    opacity: 1;
    transition: opacity 0.5s ease;
}
.slideText {
    opacity: 0.7;
    transition: opacity 0.5s ease;
}