/*
* General theme for all pages
*/
.header-bar {
    height: 50px;
    background-color: rgb(255, 255, 255); /* This will make the bar blue */
    width: 100%; /* This will make the bar span the entire width of the page */
}
.input-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
}

.input-group label {
    /* Set a minimum width for the label to ensure alignment */
    min-width: 100px;
}

/* Center the button and the input groups */
body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Creates a gap between each input group and the button */
}
.login-btn {
    display: inline;
    background-color: #008CBA; /* Background color */
    color: white; /* Foreground color */
    font-size: 20px; /* Font size */
    padding: 10px 24px; /* Padding: top/bottom, left/right */
    border: none;
    cursor: pointer; /* Changes the cursor when you hover over the button */
    text-decoration: none; /* Removes underline */
    width: 120px;
}
.contact-btn {
    display: inline;
    background-color: #008CBA; /* Background color */
    color: white; /* Foreground color */
    font-size: 20px; /* Font size */
    padding: 10px 24px; /* Padding: top/bottom, left/right */
    border: none;
    cursor: pointer; /* Changes the cursor when you hover over the button */
    text-decoration: none; /* Removes underline */
    margin-left: 20px;
    width: 120px;
}
.other-btn {
    display: inline;
    background-color: #008CBA; /* Background color */
    color: white; /* Foreground color */
    font-size: 20px; /* Font size */
    padding: 10px 24px; /* Padding: top/bottom, left/right */
    border: none;
    cursor: pointer; /* Changes the cursor when you hover over the button */
    text-decoration: none; /* Removes underline */
    margin-left: 20px;
    width: 120px;
    margin-top: 10px;
}
.login-btn:hover {
    background-color: #007B9A;
}
img {
    width: 200px;
    height: auto; /* maintain aspect ratio */
}
.container {
    position: absolute; /* Set container as a reference for positioning */
    width: 50%;
    height: 50px;
    background-color: rgba(240, 240, 240, 0.01);
}
.top-right-button {
    position: absolute;
    top: 10px; /* Adjust the distance from the top */
    right: 10px; /* Adjust the distance from the right */
    transform: translate(-50%, 0%); /* Center the button horizontally */
    background-color: #008CBA; /* Background color */
    color: white; /* Foreground color */
    font-size: 15px; /* Font size */
    padding: 10px 24px; /* Padding: top/bottom, left/right */
    border: none;
    cursor: pointer; /* Changes the cursor when you hover over the button */
    text-decoration: none; /* Removes underline */
    width: 100px;
}
.hyperlink-createAcct {
    margin-top: 15px;
    color: #008CBA;
}
.spinner {
    margin-left: 25px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fdfdfd;
    width: 15px;
    height: 15px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
/*Theme for Overview.js only*/
.header-spacer {
    width: 90vw;
    height: 15px;
    background-color: #ffffff;
}
.header-overview {
    width: 90vw;
    height: 60px;
    background-color: #ffffff;
    color: #008CBA;
    font-size: xx-large;
    font-weight: bolder;
    text-align: left;
}
.header-line {
    width: 90vw;
    height: 5px;
    background-color: #008CBA;
}
.container-overview {
    height: 90vh;
    width: 95vw;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: left;
}
/* Actual card with shadow */
.card {
    height: 200px;
    width: 200px;
    margin-left: 25px;
    margin-top: 25px;
    border-radius: 5px;
    box-shadow: 2px 3px 4px 5px rgba(14, 97, 129, 0.2);
}
.card img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 5px;
}
.para {
    color: #008CBA;
    font-weight: normal;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-variant: normal;
    font-size: medium;
    font-style: italic;
}
.user-name {
    color: #008CBA;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-decoration-line: underline;
}
.logout-dialog {
    padding: 10px 20px;           /* Add some padding */
    background-color: #ffffff;    /* Set a background color */
    border: none;                 /* Remove default borders */
    border-radius: 5px;           /* Add rounded corners */
    color: #008cba;                 /* Set text color */
    font-size: 1rem;              /* Set font size */
    cursor: pointer;              /* Change cursor to pointer on hover */
    transition: background-color 0.3s;  /* Smooth transition for hover effect */
}
.modal-buttons {
    display: inline;
    background-color: #008cba; /* Background color */
    color: #ffffff; /* Foreground color */
    font-size: 20px; /* Font size */
    padding: 10px 24px; /* Padding: top/bottom, left/right */
    border: none;
    cursor: pointer; /* Changes the cursor when you hover over the button */
    text-decoration: none; /* Removes underline */
    width: 120px;
    margin-left: 15px;
}
/*Contact page styling*/
.textarea {
    padding: 8px;
    margin: 5px 0;
    font-size: 1rem;
    min-width: 100%;
}
/*Python Course Overview Layout*/
.course-overview {
    max-width: 800px;
    margin: 50px auto;
    padding: 20px;
    box-shadow: 0 0 10px rgb(0, 140, 186);
    background-color: #f1ecec;
}

.course-overview h1 {
    text-align: center;
    margin-bottom: 20px;
}

.course-overview ul {
    list-style-type: none;
    padding: 0;
}

.course-overview li {
    margin-bottom: 10px;
}

.course-overview a {
    color: #008cba;
    text-decoration: none;
    font-size: 18px;
}

.course-overview a:hover {
    text-decoration: underline;
}
/*In-lesson content container*/
.headerFooterContainer {
    padding-left: 30px;
}
.contentContainer {
    width: 90%;
    max-width: 90%;  /* This ensures that the container doesn't overflow its parent */
    height: 500px;
    overflow-y: auto;  /* This will enable scrolling if content exceeds 700px in height */
    margin: 0 auto 20px auto;  /* This centers the container horizontally and gives 20px space at the bottom */
    border: 1px solid #e0e0e0;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    padding: 20px;
}
.questionContainer {
    padding: 10px;
    margin-bottom: 20px;
    background-color: #f7f7f7;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}
.choicesContainer {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.choicesContainer > div { /* Direct child divs, i.e., the choice containers */
    text-align: left;
    width: 100%;
}

/* Styling for buttons container */
.choicesContainer .buttons {
    display: flex;
    justify-content: center; /* centering the buttons as a group */
    gap: 10px; /* This will give space between the buttons */
    width: 100%; /* ensures that the buttons container takes full width */
}
.choicesContainer button {
    padding: 5px 15px;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    background-color: #008cba;
    color: white;
}
.feedback.correct {
    color: green;
    font-weight: bold;
}
.feedback.incorrect {
    color: red;
    font-weight: bold;
}
.gptContainer {
    width: 50%;
}
.CodeMirror {
    height: 200px !important;
    max-height: 200px !important;
}
.gptFeedbackContainer {
    max-height: 150px;
    max-width: 700px; /* Limit the maximum width */
    width: 100%;
    overflow-y: auto;
    overflow-wrap: break-word;  /* Make sure words break properly */
    word-wrap: break-word;
    margin-bottom: 16px;
}
.essayFeedbackContainer {
    max-height: 700px;
    max-width: 900px; /* Limit the maximum width */
    width: 100%;
    overflow-y: auto;
    overflow-wrap: break-word;  /* Make sure words break properly */
    word-wrap: break-word;
    margin-bottom: 16px;
}
.essay-line {
    width: 90vw;
    height: 5px;
    background-color: #008CBA;
}
.markdown-container {
    max-width: 900px;
    margin: auto;
    padding: 20px;
    max-height: 600px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.score {
    display: inline;
    color: #008CBA; /* Background color */
    font-size: 20px; /* Font size */
    padding: 10px 24px; /* Padding: top/bottom, left/right */
    border: none;
    cursor: pointer; /* Changes the cursor when you hover over the button */
    text-decoration: none; /* Removes underline */
    margin-left: 20px;
    width: 120px;
}
/* Styling for the sidebar menu */
.sidebar {
    width: 0; /* Slightly wider initial width for easier hovering */
    height: 100%;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: #008CBA;
    overflow-x: hidden;
    transition: width 0.5s;
    padding-top: 60px;
    white-space: nowrap;
}
/* Style for when the sidebar is expanded */
.sidebar.expanded {
    width: 200px;
}
.sidebar-content p {
    color: #fff; /* Set the text color to white */
    opacity: 0;
    font-weight: bold;
    cursor: pointer;
    padding-left: 20px; /* Add padding to the left of sidebar content */
    transition: opacity 0.5s 0.5s, padding-left 0.5s 0.5s; /* Add transition for padding */
}
.sidebar.expanded .sidebar-content p {
    opacity: 1;
    padding-left: 20px; /* Increase padding when sidebar is expanded */
    transition: opacity 0.5s, padding-left 0.5s;
}
.main-content {
    transition: margin-left 0.5s;
    padding: 16px;
}
.overlay {
    display: none; /* Hidden by default */
    position: fixed; /* Full-screen */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5); /* Black background with opacity */
    z-index: 2; /* Specifies a stack order */
    cursor: pointer; /* Add a pointer on hover */
    transition: 0.5s; /* Add a transition effect (when the overlay 'fades' in) */
}
.overlay.show {
    display: block; /* Display the overlay */
}
/* Styling for the sidebar tab indicator */
.sidebar-tab {
    position: absolute;
    top: 50%;
    left: -0px; /* Adjust so the tab is fully visible */
    transform: translateY(-50%);
    width: 20px;
    height: 50px;
    background-color: #008CBA;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 4; /* Make sure it's above the sidebar */
}
.arrow {
    display: block;
    width: 20px; /* Width of the arrow container */
    height: 20px; /* Height of the arrow container */
    background: #ffffff; /* Background color of the arrow */
    clip-path: polygon(0% 0%, 100% 50%, 0% 100%); /* Right-pointing triangle shape */
}
/* Table for displaying lessons and scores */
table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #008cba;
}

th {
    background-color: #f2f2f2;
}